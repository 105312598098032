<template>
  <div>
    <div id="team-list">
      <div>

        <div class="options w-100 py-2">
          <div class="d-inline-block text-center">
            <router-link :to="'/main/motors/'">
              <div class="icon mx-2">
                <span class="ukazatel-alertu" v-if="pocetVystrah>0">{{pocetVystrah}}</span>
                <div class="active-line"></div>
                <img src="/img/moje_karty.png" alt />
              </div>
              <div class="mt-1">{{$t('option.moje-karty')}}</div>
            </router-link>
          </div>

          <div class="d-inline-block text-center">
            <router-link to="/groups" class="router-link-exact-active active-line">
              <div class="icon mx-2">

                <div class="active-line"></div>
                <img src="/img/group-50.png" alt />
              </div>
              <div class="mt-1">{{$t('menu.team')}}</div>
            </router-link>
          </div>
        </div>




        <div class="mt-2 mx-2">
          {{ $t('reg.companyname') }} {{members.length}} {{ $t('team.clenu') }}
        </div>
        <h3 class="mt-1 ml-2"> {{company.name}}</h3>

        <div class="options w-100 py-2" >

        </div>

        <div class="d-flex pt-2  submenu2">
          <a @click.prevent="tab=4; load_teammotors()" href="#" :class="'mx-2 '+(tab==4 ? 'active':'')">
            {{$t('groups.subnemu.prehled')}}
          </a>
          <a @click.prevent="tab=1" href="#" :class="'mx-2 '+(tab==1 ? 'active':'')">
            {{$t('option.clenove-teamu')}}
          </a>
        </div>
      </div>


      <!-------------------------------------------------------------------------------------------------------------->
      <div class="list w-100 py-2 px-1 mt-3" v-if="tab==1">

        <!-- <h4 class="h6 pt-1">{{$t('team.members')}}</h4>-->
        <TeamMember v-for="mm in members" :key="mm.id" :m="mm" :my_role="my_role" :company="company.name" :account_id="null"></TeamMember>

      </div>

      <div v-if="tab==4 " id="motor-list">

        <div class="list  w-100 py-2 px-1 mt-3">
          <!--          <h4 class="h6 pt-1">{{$t('team.motors-head')}}</h4>
                    -->
          <MotorItem v-for="motor in motors" :key="motor.id" :motor="motor"></MotorItem>

        </div>
      </div>


    </div>
  </div>

</template>

<script>
import MotorItem from "@/components/MotorItem";
const moment = require('moment');
require('moment/locale/cs');
moment.locale('cs');
import TeamMember from "@/views/company/TeamMember";

const axios = require("axios");
export default {
  name: "Company1",
  components:{MotorItem, TeamMember },
  data(){
    return {
      pocetVystrah:0,
      members: [],
      company: {name:'#'},
      tab: 4,
      invitations: [],
      motors: [],
      invResultClass: '',
      my_role: 0,
    }
  }, mounted(){
    this.loadTeam()
  },
  methods: {
    df: function (time, format) {
      moment.locale(this.$i18n.locale);
      var a = moment(time);

      return a.format(format ? format : "LLL")
    },

    loadTeam(){
        axios.get(process.env.VUE_APP_API_ROOT_URL + "/company/members.json?t="+new Date().getTime()).then(result => {
          if (result.status==200){
            this.members = result.data.members
            this.company = result.data.company

            for(var i=0; i< this.members.length; i++) {
              this.members[i].visiblesel=false
            }
            this.load_teammotors()
          }
        })
    },
    load_teammotors() {
        axios.get(`${process.env.VUE_APP_API_ROOT_URL}/company/motors?t=${new Date().getTime()}`).then(result => {
          //console.log('res', result.data)
          if (result.status == 200 || result.status == 304) {
            this.motors = result.data.motors
          }
        })
    },
  }
}
</script>

<style scoped>

</style>

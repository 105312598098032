<template>
    <div class="d-inline-block motor m-1" >
        <router-link :to="'/main/motors/' + motor.token">
            <div class="single p-1 d-flex">
                <div class="media">


                    <div :class="'icon flex-fill align-self-center '+barvaKolecka">
                       <!-- <i class="far fa-envelope" v-if="mameUpozorneni"></i>-->
                        <i class="fas fa-circle state "></i>
                        <img class="mx-2" :src="obrazekMotoru" alt />
                    </div>
                 <!--   <div class="info media-body text-right align-self-center p-1">
                        <h5 class="font-weight-bold h5 text-break m-1 mr-2">CAG</h5>
                    </div>-->
                </div>
                <div class="detail mt-3 mb-2">
                    <div class="row m-0">
                        <div class="col col-12">
                            <div class="row mb-1">
                                <div class="col col-5 font-weight-bold pr-1">{{$t('motor.muj-nazev')}}:</div>
                                <div class="col col-7 font-weight-bold bg-white text-break pl-1">{{motor._name}}</div>
                            </div>
                            <div class="row mb-1">
                                <div class="col col-5 font-weight-bold pr-1">{{ $t('motor.rada') }}:</div>
                                <div class="col col-7 bg-white text-break pl-1">{{ motor.productline }}</div>
                            </div>
                            <div class="row mb-1">
                                <div class="col col-5 font-weight-bold pr-1">{{$t('motor.list.vykon-motoru')}}:</div>
                                <div class="col col-7 bg-white text-break pl-1">{{motor.power}} kW</div>
                            </div>

                            <div class="row mb-1">
                                <div class="col col-5 font-weight-bold pr-1">{{$t('motor.list.pocet-polu')}}:</div>
                                <div class="col col-7 bg-white text-break pl-1">{{motor.pole}}</div>
                            </div>

                            <div class="row mb-1">
                                <div class="col col-5 font-weight-bold pr-1">{{$t('motor.list.sn')}}:</div>
                                <div class="col col-7 bg-white text-break pl-1">{{motor.sn}}</div>
                            </div>
                      <!--      <div class="row mb-1">
                                <div class="col col-5 font-weight-bold pr-1">Vlastník:</div>
                                <div class="col col-7 font-weight-bold bg-white text-break pl-1">{{motor.username}}</div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "MotorItem",
        props: ['motor'],
        computed:{
          barvaKolecka(){

              if (this.motor){
                if (this.motor.alert_level>=2) return   "text-danger"
                if (this.motor.alert_level==1) return   "text-warning"
               // if (this.motor.vStateColor == 'Red' || this.motor.tStateColor=='Red') return "text-danger"
              }
              return "text-success"
          },

          obrazekMotoru(){
            if (this.motor.img1_url) return this.motor.img2_url
            return '/img/motor_ikona.png'

          },

          mameUpozorneni(){
            if (!this.motor.notifications) return false
            for(let i=0; i< this.motor.notifications.length; i++){
              let u = this.motor.notifications[i]
              if (u.active) return true;
            }
            return false
          }

        }
    }
</script>

<style scoped >

.motor  .fa-envelope{
  font-size: 160%;
  color: #fab20a;
  position:absolute;
  top: -30px;
  left:20px;

}

.detail{
  width:100%;
}


</style>
